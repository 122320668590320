import React from 'react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { ThirdwebNftMedia, useValidDirectListings, useAddress, useContract, DirectListingV3 } from '@thirdweb-dev/react'
import { marketplaceContractAddress } from '~/constants/marketplace'
import { chilizGenesisChainId } from '~/constants/chiliz'
import { ChainContext } from '~/context/chain'
import { findBestActiveDirectListing } from '~/components/shared/listing'
import { LinkButton } from '~/components/LinkButton'
import { Collection } from '~/domain/Collection'
import { Item } from '~/domain/Item'
import { NftCardBadge } from '~/components/NftCardBadge'
import styles from './index.module.scss'

interface NftCardProps {
  collection: Collection
  nft: Item
  dropMintPrice?: string
  dropMintCurrencySymbol?: string
}

export const NftCard: React.FC<NftCardProps> = ({ collection, nft: item, dropMintPrice, dropMintCurrencySymbol }) => {
  const address = useAddress()
  const { activeChain } = React.useContext(ChainContext)
  const { contract } = useContract(marketplaceContractAddress(activeChain), 'marketplace-v3')
  const { data: listings, isLoading } = useValidDirectListings(
    contract,
    {
      tokenContract: collection.contract.address,
      tokenId: item.nft.metadata.id,
    }
  )
  const { data: vaultListings, isLoading: isVaultListingsLoading } = useValidDirectListings(
    contract,
    { tokenContract: item.vault?.contract.address }
  )

  let link: string

  if (collection.contract.chainId === chilizGenesisChainId) {
    link = collection.contract.address === '0xfb917E76927e2BED4361bAa088c6e680E8546c4B'
      ? '/chiliz' // The Genesis
      : collection.contract.address === '0xC3BFbFFD23c6cf71e41B16d90EB4D371D90C58e0'
        ? '/club' // Legend Club
        : item.url
  } else {
    link = item.url
  }

  let price: JSX.Element
  let action: string

  if (item.isFullItem()) {
    const hasNoListingData = isLoading || !listings || listings.length === 0

    action = isLoading
      ? 'Loading'
      : (
        address &&
        item.nft.owner.toLowerCase() !== address.toLowerCase() &&
        listings &&
        listings.length > 0
          ? 'Buy'
          : 'Details'
      )

    const getPriceDisplayValue = () => {
      const listing = findBestActiveDirectListing(listings as DirectListingV3[])

      return `${listing.currencyValuePerToken.displayValue} ${listing.currencyValuePerToken.symbol}`
    }

    const priceDisplayValue = isLoading
      ? '---'
      : (
        hasNoListingData
          ? 'Not Listed'
          : getPriceDisplayValue()
      )

    if (hasNoListingData) {
      price = (
        <span className={ `
          ${styles['nft-card__price']}
          ${styles['nft-card__price--not-listed']}
        ` }>
          { priceDisplayValue }
        </span>
      )
    } else {
      price = (
        <span className={ styles['nft-card__price'] }>
          <span className={ styles['nft-card__price-label'] }>
            { 'Price' }
          </span>
          { priceDisplayValue }
        </span>
      )
    }
  } else if (item.isVault()) {
    const hasNoDropData = dropMintPrice === undefined || dropMintCurrencySymbol === undefined
    const hasNoListingData = isVaultListingsLoading || !vaultListings || vaultListings.length === 0

    action = isLoading
      ? 'Loading'
      : (
        address &&
        item.nft.owner.toLowerCase() !== address.toLowerCase() &&
        (!hasNoDropData || !hasNoListingData)
          ? 'Buy'
          : 'Details'
      )

    const getPriceDisplayValue = () => {
      if (!hasNoDropData) return `${dropMintPrice} ${dropMintCurrencySymbol}`

      const listing = findBestActiveDirectListing(vaultListings as DirectListingV3[])

      return `${listing.currencyValuePerToken.displayValue} ${listing.currencyValuePerToken.symbol}`
    }

    const priceDisplayValue = isVaultListingsLoading
      ? '---'
      : (
        hasNoDropData && hasNoListingData
          ? 'Not Listed'
          : getPriceDisplayValue()
      )

    if (hasNoDropData && hasNoListingData) {
      price = (
        <span className={ `
          ${styles['nft-card__price']}
          ${styles['nft-card__price--not-listed']}
        ` }>
          { priceDisplayValue }
        </span>
      )
    } else {
      price = (
        <span className={ styles['nft-card__price'] }>
          <span className={ styles['nft-card__price-label'] }>
            { !hasNoDropData ? 'Mint price' : 'Floor price' }
          </span>
          { priceDisplayValue }
        </span>
      )
    }
  } else { // item.isFragment()
    const fragmentListings = vaultListings?.filter((listing) => listing.tokenId === item.fragmentId)

    const hasNoListingData = isVaultListingsLoading ||
      !vaultListings ||
      vaultListings.length === 0 ||
      fragmentListings?.length === 0

    action = isLoading
      ? 'Loading'
      : (
        address &&
        item.nft.owner.toLowerCase() !== address.toLowerCase() &&
        fragmentListings &&
        fragmentListings.length > 0
          ? 'Buy'
          : 'Details'
      )

    const getPriceDisplayValue = () => {
      const listing = findBestActiveDirectListing(fragmentListings as DirectListingV3[])

      return `${listing.currencyValuePerToken.displayValue} ${listing.currencyValuePerToken.symbol}`
    }

    const priceDisplayValue = isVaultListingsLoading
      ? '---'
      : (
        hasNoListingData
          ? 'Not Listed'
          : getPriceDisplayValue()
      )

    if (hasNoListingData) {
      price = (
        <span className={ `
          ${styles['nft-card__price']}
          ${styles['nft-card__price--not-listed']}
        ` }>
          { priceDisplayValue }
        </span>
      )
    } else {
      price = (
        <span className={ styles['nft-card__price'] }>
          <span className={ styles['nft-card__price-label'] }>
            { 'Price' }
          </span>
          { priceDisplayValue }
        </span>
      )
    }
  }

  if (!isLoading && !contract) {
    console.error(
      `Undefined marketplace contract "${marketplaceContractAddress(activeChain)}" for series "${collection.seriesId}"`
    )

    return null
  }

  return (
    <a
      href={ link }
      className={ styles['nft-card__card'] }
      data-testid="nft-card"
    >
      <NftCardBadge
        nft={ item }
        className={ 'card' }
      />
      <div className={ styles['nft-card__media-wrapper'] }>
        <ThirdwebNftMedia
          metadata={ item.nft.metadata }
          className={ styles['nft-card__media'] }
        />
        <LinkButton
          size="small"
          className={ styles['nft-card__button'] }
          icon={ faArrowRight }
        >
          { action }
        </LinkButton>
      </div>

      <span className={ styles['nft-card__name'] }>
        { item.nft.metadata.name }
      </span>

      { price }
    </a>
  )
}
